import { Avatar, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { format } from "date-fns";
import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CustomButton from "../../../../components/button/custom-button.component";
import routes from "../../../../components/navigation/routes";
import Spacer from "../../../../components/utils/spacer.component";
import { fitnessClassSessionSelector } from "../../../../services/fitness_class/session/fitness-class-session-slice.services";
import { profileSelector } from "../../../../services/profile/profile-slice.service";

function SessionDetails() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const location = useLocation();

  const { getFitnessClassSessionObj } = useSelector(fitnessClassSessionSelector);
  const { getProfileDetailObj } = useSelector(profileSelector);

  return (
    <Grid container flexDirection="column" rowSpacing={1}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Grid item>
            <Typography fontSize={theme.fonts.fontSizes.size14}>
              {getFitnessClassSessionObj.data.class.category.label}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h5" fontWeight="bold">
              {getFitnessClassSessionObj.data.class.name}
            </Typography>
          </Grid>
        </Grid>
        {!isMobile &&
          format(new Date(), "yyyy-MM-dd HH:mm:ss") < getFitnessClassSessionObj.data.startAt && (
            <Grid item xs={2}>
              {getFitnessClassSessionObj.data.maxCapacity && (
                <Typography
                  textAlign="center"
                  fontWeight="bold"
                  sx={{ color: theme.palette.colors.ui.error }}
                >
                  Fully Booked
                </Typography>
              )}
              <CustomButton
                disabled={
                  getProfileDetailObj.status === "pending" ||
                  (getFitnessClassSessionObj.data.requireVerification &&
                    getProfileDetailObj.status === "succeeded" &&
                    getProfileDetailObj.data &&
                    getProfileDetailObj.data.status !== "verified") ||
                  getFitnessClassSessionObj.data.booked ||
                  getFitnessClassSessionObj.data.maxCapacity
                }
                fontSize={theme.fonts.fontSizes.size18}
                onClick={() =>
                  navigate(routes.FITNESSCLASSSESSIONBOOKINGSUMMARY, {
                    state: {
                      sessionId: getFitnessClassSessionObj.data.id,
                      packageCode:
                        location.state && location.state.packageCode
                          ? location.state.packageCode
                          : null,
                    },
                  })
                }
              >
                {getFitnessClassSessionObj.data.booked ? "Booked" : "Book"}
              </CustomButton>
            </Grid>
          )}
      </Grid>
      <Spacer size="m" />
      <Grid item>
        <Typography>
          {getFitnessClassSessionObj.data.startTime} ({getFitnessClassSessionObj.data.durationMins}
          mins) | {getFitnessClassSessionObj.data.date}
        </Typography>
      </Grid>
      <Grid item>
        <Typography>{getFitnessClassSessionObj.data.business.name}</Typography>
      </Grid>
      <Spacer size="m" />
      <Grid item container rowSpacing={1}>
        <Grid item xs={12}>
          <Typography fontWeight="bold">Trainers</Typography>
        </Grid>
        <Grid container item columnSpacing={2}>
          <Grid item>
            <Grid container alignItems="center">
              <Avatar
                src={getFitnessClassSessionObj.data.staff.imagePath}
                sx={{
                  width: "40px",
                  height: "40px",
                }}
              />
              <Spacer position="left" />
              <Typography>{getFitnessClassSessionObj.data.staff.name}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {isMobile &&
        format(new Date(), "yyyy-MM-dd HH:mm:ss") < getFitnessClassSessionObj.data.startAt && (
          <>
            <Spacer size="m" />
            <Grid item xs={12}>
              {getFitnessClassSessionObj.data.maxCapacity && (
                <Typography
                  textAlign="center"
                  fontWeight="bold"
                  sx={{ color: theme.palette.colors.ui.error }}
                >
                  Fully Booked
                </Typography>
              )}
              <CustomButton
                disabled={
                  getFitnessClassSessionObj.data.booked ||
                  getFitnessClassSessionObj.data.maxCapacity
                }
                fontSize={theme.fonts.fontSizes.size18}
                onClick={() =>
                  navigate(routes.FITNESSCLASSSESSIONBOOKINGSUMMARY, {
                    state: {
                      sessionId: getFitnessClassSessionObj.data.id,
                      packageCode:
                        location.state && location.state.packageCode
                          ? location.state.packageCode
                          : null,
                    },
                  })
                }
              >
                {getFitnessClassSessionObj.data.booked ? "Booked" : "Book"}
              </CustomButton>
            </Grid>
          </>
        )}
    </Grid>
  );
}

export default SessionDetails;
