import PlaceIcon from "@mui/icons-material/Place";
import { Avatar, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { format } from "date-fns";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../../components/button/custom-button.component";
import routes from "../../../../components/navigation/routes";
import DialogBox from "../../../../components/notification/dialog-box.component";
import PaddedView from "../../../../components/utils/padded-view.component";
import Spacer from "../../../../components/utils/spacer.component";
import PurchaseAsGiftImage from "../../../../assets/images/purchase-as-gift.png";

function FitnessClassCard({ itemDetails, packageCode }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const [showOnlyPurchasedAsGiftModal, setShowOnlyPurchasedAsGiftModal] = useState(false);

  const handleClick = (event, buttonClick) => {
    if (!buttonClick) {
      if (itemDetails.booked && !itemDetails.maxCapacity) {
        setShowOnlyPurchasedAsGiftModal(true);
      } else {
        navigate(
          { pathname: routes.FITNESSCLASS, search: `?sessionId=${itemDetails.id}` },
          { state: { sessionId: itemDetails.id, packageCode } },
        );
      }
    } else {
      navigate(routes.FITNESSCLASSSESSIONBOOKINGSUMMARY, {
        state: { sessionId: itemDetails.id, packageCode },
      });
      event.stopPropagation();
    }
  };

  return (
    <>
      <DialogBox
        title="Only allowed to purchase as gift"
        isShow={showOnlyPurchasedAsGiftModal}
        setIsShowAlert={setShowOnlyPurchasedAsGiftModal}
        message={
          <Grid item>
            <Grid container display="flex" justifyContent="center">
              <img
                width={250}
                src={PurchaseAsGiftImage}
                alt="purchase-as-gift"
                style={{ objectFit: "contain" }}
              />
            </Grid>
            <Spacer size="m" />
            <Typography sx={{ textAlign: "center" }}>
              You have purchased this session, so you can only purchase this as a gift for others.
            </Typography>
          </Grid>
        }
        isConfirmHandle={() => {
          setShowOnlyPurchasedAsGiftModal(false);
          navigate(routes.FITNESSCLASSSESSIONBOOKINGSUMMARY, {
            state: { sessionId: itemDetails.id, packageCode, onlyAllowPurchasedAsGift: true },
          });
        }}
        buttonText="Proceed"
      />
      <Grid
        onClick={(event) => handleClick(event, false)}
        item
        sx={{
          backgroundColor: theme.palette.colors.bg.primary,
          borderRadius: `${theme.shape.borderRadius[2]}px`,
          objectFit: "cover",
          cursor: "pointer",
          "&:hover .fitnessTitle": { color: theme.palette.colors.brand.primary },
        }}
      >
        <PaddedView multiples={2}>
          <Grid container rowSpacing={1}>
            <Grid item>
              <Typography fontSize={theme.fonts.fontSizes.size14}>
                {itemDetails.category.label}
              </Typography>
            </Grid>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography
                  fontSize={theme.fonts.fontSizes.size20}
                  fontWeight="bold"
                  className="fitnessTitle"
                >
                  {itemDetails.class.name}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  fontSize={theme.fonts.fontSizes.size20}
                  sx={{ color: theme.palette.colors.brand.primary }}
                >
                  RM {itemDetails.price}
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Spacer size="m" />
              <Typography>
                {itemDetails.startTime} ({itemDetails.durationMins}mins)
              </Typography>
            </Grid>
            <Grid
              container
              item
              display="flex"
              columnSpacing={2}
              flexDirection={isMobile ? "column" : "row"}
            >
              <Grid item>
                <Grid container alignItems="center">
                  <Avatar
                    src={itemDetails.staff.imagePath}
                    sx={{
                      width: "25px",
                      height: "25px",
                    }}
                  />
                  <Spacer position="left" />
                  <Typography>w/ {itemDetails.staff.name}</Typography>
                </Grid>
                {isMobile && <Spacer />}
              </Grid>
            </Grid>
            <Grid item container justifyContent="space-between" alignItems="center">
              <Grid item display="flex" alignContent="center">
                <PlaceIcon color="primary" />
                <Spacer position="left" />
                <Typography>{itemDetails.business.name}</Typography>
              </Grid>
              {format(new Date(), "yyyy-MM-dd HH:mm:ss") < itemDetails.startAt && (
                <Grid item xs={isMobile ? 12 : 2}>
                  {isMobile && <Spacer size="l" />}
                  {itemDetails.maxCapacity && (
                    <Typography
                      textAlign="center"
                      fontWeight="bold"
                      sx={{ color: theme.palette.colors.ui.error }}
                    >
                      Fully Booked
                    </Typography>
                  )}
                  <CustomButton
                    disabled={itemDetails.booked || itemDetails.maxCapacity}
                    fontSize={theme.fonts.fontSizes.size16}
                    onClick={(event) => handleClick(event, true)}
                  >
                    {itemDetails.booked ? "Booked" : "Book"}
                  </CustomButton>
                </Grid>
              )}
            </Grid>
          </Grid>
        </PaddedView>
      </Grid>
    </>
  );
}

FitnessClassCard.defaultProps = {
  itemDetails: PropTypes.shape({
    id: null,
    date: "",
    durationMins: 0,
    startTime: "",
    startAt: "",
    maxCapacity: false,
    price: "",
    class: PropTypes.shape({
      name: "",
    }),
    category: PropTypes.shape({ label: "" }),
    staff: PropTypes.shape({
      id: null,
      name: "",
      imagePath: "",
    }),
    business: PropTypes.shape({
      id: null,
      name: "",
      city: "",
      reviews: PropTypes.shape({
        averageRatings: 0,
      }),
      images: PropTypes.arrayOf(
        PropTypes.shape({
          imagePath: "",
        }),
      ),
    }),
    booked: false,
  }),
};

FitnessClassCard.propTypes = {
  packageCode: PropTypes.string.isRequired,
  itemDetails: PropTypes.shape({
    id: PropTypes.number,
    date: PropTypes.string,
    durationMins: PropTypes.number,
    startTime: PropTypes.string,
    startAt: PropTypes.string,
    maxCapacity: PropTypes.bool,
    price: PropTypes.string,
    class: PropTypes.shape({
      name: PropTypes.string,
    }),
    category: PropTypes.shape({ label: PropTypes.string }),
    staff: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      imagePath: PropTypes.string,
    }),
    business: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      city: PropTypes.string,
      reviews: PropTypes.shape({
        averageRatings: PropTypes.string,
      }),
      images: PropTypes.arrayOf(
        PropTypes.shape({
          imagePath: PropTypes.string,
        }),
      ),
    }),
    booked: PropTypes.bool,
  }),
};

export default FitnessClassCard;
