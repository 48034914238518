import { Grid, styled, Typography, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import HorizontalDivider from "../../../../components/utils/horizontal-divider.component";

const SectionContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.colors.bg.primary,
  padding: "20px",
  borderRadius: `${theme.shape.borderRadius[1]}px`,
}));

function PackageRedeemHistory({ itemDetails }) {
  const theme = useTheme();

  return (
    <SectionContainer>
      {itemDetails.sessions.histories.length > 0 ? (
        itemDetails.sessions.histories.map((item) => (
          <Grid item key={uuidv4()}>
            <Grid item>
              <Typography fontWeight="bold" sx={{ color: theme.palette.colors.brand.primary }}>
                {item.session.class.category.label}
              </Typography>
            </Grid>
            <Grid item>
              <Typography fontWeight="bold">{item.session.class.name}</Typography>
            </Grid>
            <Grid item>
              <Typography>
                {item.session.startTime} ({item.session.durationMins} mins)
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                sx={{
                  color: theme.palette.colors.text.secondary,
                  fontSize: theme.fonts.fontSizes.size14,
                }}
              >
                w/ {item.session.staff.name}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                sx={{
                  color: theme.palette.colors.brand.primary,
                  fontSize: theme.fonts.fontSizes.size14,
                }}
              >
                Redeemed by: {item.redeemBy.firstName} {item.redeemBy.lastName}
              </Typography>
            </Grid>
            <HorizontalDivider spacerSize="s" />
          </Grid>
        ))
      ) : (
        <Typography textAlign="center">No redeem history</Typography>
      )}
    </SectionContainer>
  );
}

PackageRedeemHistory.propTypes = {
  itemDetails: PropTypes.shape({
    id: PropTypes.number,
    code: PropTypes.string,
    plan: PropTypes.shape({
      title: PropTypes.string,
      imagePath: PropTypes.string,
    }),
    sessions: PropTypes.shape({
      claimedCount: PropTypes.number,
      noOfSessions: PropTypes.number,
      histories: PropTypes.arrayOf(
        PropTypes.shape({
          redeemBy: PropTypes.shape({
            firstName: PropTypes.string,
            lastName: PropTypes.string,
          }),
          session: PropTypes.shape({
            durationMins: PropTypes.number,
            startTime: PropTypes.string,
            class: PropTypes.shape({
              name: PropTypes.string,
              category: PropTypes.shape({
                label: PropTypes.string,
              }),
            }),
            staff: PropTypes.shape({
              id: PropTypes.number,
              name: PropTypes.string,
            }),
          }),
        }),
      ),
    }),
    businesses: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    ),
    category: PropTypes.shape({
      type: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          label: PropTypes.string,
        }),
      ),
    }),
    paymentTransaction: PropTypes.shape({
      method: PropTypes.string,
      paidAt: PropTypes.string,
      total: PropTypes.string,
    }),
  }).isRequired,
};

export default PackageRedeemHistory;
